import path from 'path';

import { GetServerSideProps, NextPage } from 'next';
import dynamic from 'next/dynamic';

import { cacheControl } from '@hultafors/shared/helpers';

import { getApi } from '@hultafors/toeguard/api';
import { createMeta } from '@hultafors/toeguard/helpers';
import {
  GlobalFields,
  LandingPageFragment,
  PageProps,
} from '@hultafors/toeguard/types';
const ContentBlock = dynamic(() =>
  import('@hultafors/toeguard/components').then((mod) => mod.ContentBlock),
);
const ExploreMoreSection = dynamic(() =>
  import('@hultafors/toeguard/components').then((mod) => mod.ExploreMoreSection),
);
const ExploreMorePlugs = dynamic(() =>
  import('@hultafors/toeguard/components').then((mod) => mod.ExploreMorePlugs),
);
const Hero = dynamic(() =>
  import('@hultafors/toeguard/components').then((mod) => mod.Hero),
);
const Page = dynamic(() =>
  import('@hultafors/toeguard/components').then((mod) => mod.Page),
);
const PagePlugs = dynamic(() =>
  import('@hultafors/toeguard/components').then((mod) => mod.PagePlugs),
);
const SeoSection = dynamic(() =>
  import('@hultafors/toeguard/components').then((mod) => mod.SeoSection),
);

const ColorPlugs = dynamic(() =>
  import('@hultafors/toeguard/components').then((mod) => mod.ColorPlugs),
);
const BigPlug = dynamic(() =>
  import('@hultafors/toeguard/components').then((mod) => mod.BigPlug),
);

// Needed for standalone build, for some reason
path.resolve('./next.config.js');

interface LandingPageProps extends PageProps {
  content: LandingPageFragment;
}

const LandingPage: NextPage<LandingPageProps> = ({ content, settings }) => {
  return (
    <Page
      metadata={createMeta(content.seo, settings.language.lang)}
      market={settings.market}
    >
      <Hero
        title={content.heroTitle}
        image={content.heroImage}
        imageMobile={content.heroImageMobile}
        heroCtaTitle1={content.heroFirstCtaText}
        heroCtaUrl1={content.heroFirstCtaLink}
        heroCtaTitle2={content.heroSecondCtaText}
        heroCtaUrl2={content.heroSecondCtaLink}
      />
      <ContentBlock
        title={content.contentAreaTitle}
        description={content.contentAreaDescription}
        linkText={content.contentAreaLinkText}
        linkUrl={content.contentAreaLinkUrl}
      />
      <BigPlug
        title={content.bigPlugTitle}
        image={content.bigPlugImage}
        CTAText={content.bigPlugCtaText}
        CTAUrl={content.bigPlugCtaUrl}
      />
      <ExploreMoreSection className="NoBorder">
        <ExploreMorePlugs
          plugs={content.informationPagePlugs}
          CTAText={content.exploreLabel}
        />
      </ExploreMoreSection>
      <PagePlugs plugs={content.pagePlugs} />
      <ColorPlugs plugs={content.colorPlugs} />
      <SeoSection
        title={content.seoSectionTitle}
        description={content.seoSectionDescription}
      />
    </Page>
  );
};

export const getServerSideProps: GetServerSideProps<LandingPageProps> = async ({
  res,
  req,
  locale,
}) => {
  try {
    const { settings, dato } = getApi(req, locale);

    const {
      data: { landingPage: content, ...global },
    } = await dato.getLandingPage();

    res.setHeader('Cache-Control', cacheControl.page);
    return {
      props: {
        content,
        settings,
        ...(global as GlobalFields),
      },
    };
  } catch (error) {
    console.error(error);
    return { notFound: true };
  }
};

export default LandingPage;
