import { ImageFragment, SeoFragment } from '@hultafors/toeguard/types';

export interface MetaData {
  description?: string;
  image?: Partial<ImageFragment>;
  lang?: string;
  title?: string;
  type?: string;
}

/**
 * @desc Generates OG tags object from Dato SEO object.
 * @param object Dato SEO object
 * @param string locale, defaulting to "en"
 * @return object
 */
export const createMeta = (seo: SeoFragment, lang = 'en'): MetaData => {
  if (!seo) {
    return {
      lang,
      title: '',
      description: '',
    };
  }
  const tags: MetaData = {
    lang,
    title: seo.title ? seo.title : '',
    description: seo.description ? seo.description : '',
  };

  if (seo.image) {
    tags.image = seo.image;
    // tags.image.width = 310;
    // tags.image.height = 310;
  }
  return tags;
};

/**
 * @desc Generates OG tags object
 * @param string title
 * @param string description
 * @param string image url
 * @param string locale, defaulting to "en"
 * @return object
 */
export const createMetaManually = ({
  title,
  description,
  imageUrl,
  lang = 'en',
}: {
  title?: string | null;
  description?: string | null;
  imageUrl?: string | null;
  lang?: string;
}): MetaData => {
  const tags: MetaData = {
    lang,
    title: title ?? '',
    description: description ?? '',
  };

  if (imageUrl) {
    tags.image = {
      url: imageUrl,
    };
  }

  return tags;
};
